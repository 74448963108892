import { Controller } from "stimulus"
import 'bootstrap-autocomplete'
import addresser from 'addresser'


export default class extends Controller {
  static targets = [
    "addressInput",
    "dbAddressCard",
    "addressCard",
    "addressCardTitle",
    "street1Input",
    "street2Input",
    "cityInput",
    "stateInput",
    "zipInput",
    "loadingMessage",
    "notInSystemMessage"
  ]

  connect() {
    $(this.addressInputTarget).autoComplete({
      resolverSettings: {
        url: '/api/addresses/search.json',
        queryKey: 'q',
        requestThrottling: 400
      },
      events: {
        searchPre: this.searchPre.bind(this),
        searchPost: this.searchPost.bind(this)
      },
      minLength: 1,
      noResultsText: ''
    });
    $(this.addressInputTarget).on("autocomplete.select", this.select.bind(this));
    $(this.addressInputTarget).on("autocomplete.freevalue", this.freevalue.bind(this));
  }

  select(event, item) {
    if (item.multi_unit) {
      // This is a building with multiple units.
      // Call the backend a second time to get the units so the user can select one.
      $(this.addressInputTarget).autoComplete('set', item.text);
      $(this.addressInputTarget).autoComplete('show');
      $(this.addressInputTarget).autoComplete('clear');
    }
  }

  freevalue(event, value) {
    let parsed;
    try {
      parsed = addresser.parseAddress(this.addressInputTarget.value);
    } catch(e){
      parsed = {
        addressLine1: this.addressInputTarget.value,
        placeName: '',
        stateAbbreviation: '',
        zipCode: ''
      }
    }
    console.log("Parsed",parsed)
    if(parsed){
      this.street1InputTarget.value   = parsed.addressLine1
      this.street2InputTarget.value   = ''
      this.cityInputTarget.value      = parsed.placeName || ''
      this.stateInputTarget.value     = parsed.stateAbbreviation || ''
      this.zipInputTarget.value       = parsed.zipCode || ''
    }

    // this.addressInputTarget.value = ''

    $(this.notInSystemMessageTarget).addClass('invisible')
    $(this.dbAddressCardTarget).hide()
    $(this.addressCardTarget).show()
    this.addressCardTitleTarget.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'})
  }

  searchPre(newValue) {
    $(this.loadingMessageTarget).removeClass('invisible')

    return newValue
  }

  searchPost(resultsFromServer) {
    $(this.loadingMessageTarget).addClass('invisible')

    if (resultsFromServer.length == 0) {
      $(this.notInSystemMessageTarget).removeClass('invisible')

    } else {
      console.log('parsed');
      $(this.notInSystemMessageTarget).addClass('invisible')
    }

    return resultsFromServer
  }

  tryAgain() {
    $(this.addressInputTarget).val(null)
    $(this.addressCardTarget).hide()
    $(this.dbAddressCardTarget).show()
    $(this.loadingMessageTarget).addClass('invisible')
    $(this.notInSystemMessageTarget).addClass('invisible')
    this.addressCardTitleTarget.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'})
  }
}
